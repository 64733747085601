import React from 'react';
import { useSelector } from '../../../common/components/runtime-context';
import { getCategoryMenuWidgetData } from '../../aggregated-category-menu/selectors';
import LinkList from '../link-list-new';
import LinkText from '../link-text';

const CategoryMenuNew = () => {
  const categoryMenuWidgetData = useSelector(getCategoryMenuWidgetData);
  const links = React.useMemo(() => {
    const { categoryMenu } = categoryMenuWidgetData;

    return categoryMenu.map((category) => ({
      key: category.id,
      path: category.path,
      text: (
        <LinkText
          postCount={category.display.postCount}
          a11yText={category.display.a11yText}
        >
          {category.display.text}
        </LinkText>
      ),
    }));
  }, [categoryMenuWidgetData]);

  return (
    <nav aria-label={categoryMenuWidgetData.areaLabel}>
      <LinkList
        links={links}
        emptyState={{ text: categoryMenuWidgetData.emptyState }}
      />
    </nav>
  );
};

export default CategoryMenuNew;
